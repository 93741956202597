

























































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CusDatePicker from '@/components/controls/cus-date-picker.vue';
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util';
import ListBase from '@/lib/listbase';
import ChartOperation from './chart-operation.vue';
import Widgets from './widgets.vue';

@Component({
    components: { CusDatePicker, VWidget, ChartOperation, Widgets },
    data() {
        return {
        }
    },
})

export default class HomeView extends ListBase {
    loadingA: boolean = false;
    opear_items: Array<any> = [
        { key: 'total_clases', label: 'Cursos y Clases', value: '0' },
        { key: 'total_students', label: 'Asistentes', value: '0' },
        { key: 'total_teachers', label: 'Ponentes', value: '0' },
        { key: 'total_lawyers', label: 'Agremiados', value: '0' },
        { key: 'total_applications', label: 'Solicitudes', value: '0' },
    ];

    get loading() {
        return this.$store.state.dashboard.loading;
    };

    get dashActivity() {
        return this.$store.state.dashboard.activity;
    };

    get canViewHome() {
        return this.hasPermission('Home.view');
    };

    formatPrice(price) {
        let sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
        return sign + ' ' + Math.round(price);
    }

    async loadActivities() {
        this.loadingA = true;
        await this.$store.dispatch({
            type: 'dashboard/getDashActivity'
        });
        this.loadingA = false;
    }

    async mounted() {
        if (this.canViewHome)
            this.loadActivities();
    }

    async created() {

    }
}
