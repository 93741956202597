


































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import VWidget from '@/components/VWidget.vue';
import Util from '@/lib/util';

@Component({
    components: { VWidget },
})

export default class Widgets extends AbpBase {
    loading: boolean = false;
    checking: boolean = false;
    heartbeats: Array<any> = [];

    get widgets() {
        return [
            { name: 'Habilitados', icon: 'thumb_up_alt', value: '# ' + (this.values[0] == undefined ? '0' : this.values[0]), desc: 'Agremiados Habilitados' },
            { name: 'Inhabilitados', icon: 'thumb_down_alt', value: '# ' + (this.values[1] == undefined ? '0' : this.values[1]), desc: 'Agremiados Inhabilitados' },
            { name: 'Fallecidos', icon: 'mdi-heart-pulse', value: '# ' + (this.values[2] == undefined ? '0' : this.values[2]), desc: 'Agremiados Fallecidos' },
        ];
    }
    
    get values() {
        return this.$store.state.dashboard.widgets;
    }

    get month() {
        return (this as any).$moment().format('MMMM');
    }

    async loadData() {
        this.checking = true

        await this.$store.dispatch({
            type: 'dashboard/getWidgetValues'
        });

        this.checking = false
    }

    mounted() { 
        this.loadData();
    }
}
