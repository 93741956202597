

















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import Util from '@/lib/util';
import VWidget from '@/components/VWidget.vue';
import ApexCharts from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'

@Component({
    components: { apexcharts: VueApexCharts, VWidget },
})

export default class ChartOperation extends ListBase {
    loadingDash: boolean = false;
    options: any = {
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        title: {
            text: 'Reporte de Solicitudes por mes',
            align: 'left'
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.7,
                opacityTo: 0,
                stops: [20, 90, 100]
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
        },
        xaxis: {
            type: 'text',
            categories: this.monthsRange
        },
        //tooltip: {
        //    x: {
        //        format: 'dd/MM/yy HH:mm'
        //    },
        //},
        legend: {
            position: 'top',
            horizontalAlign: 'center',
            floating: true,
            offsetY: -15
        }
    };

    get series() {
        return this.$store.state.dashboard.sales;
    }

    get monthsRange() {
        let list = this.gCommonList('months');
        let months = [];
        list.forEach(item => {
            months.push(item.name.substr(0, 3));
        });

        return months;
    }

    async loadDashSales() {
        this.loadingDash = true;
        await this.$store.dispatch({
            type: 'dashboard/getDashSales'
        });
        this.loadingDash = false;
    }

    mounted() { 
        this.loadDashSales();
    }
}
